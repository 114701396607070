/* eslint-disable react-hooks/rules-of-hooks */
import { navigate } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import { setPageRegion, redirectedToRegion, setRegion } from '../state/app'

export const DEFAULT_REGION = 'IN'

export const SUPPORTED_REGIONS = [
  { code: 'IN', name: 'India', path: '' },
  { code: 'ME', name: 'Middle East', path: 'middle-east' },
  { code: 'ID', name: 'Indonesia', path: 'indonesia' },
  { code: 'SG', name: 'Singapore', path: 'singapore' },
]

export const isSupportedRegion = region => SUPPORTED_REGIONS.map(r => r.code).includes(region)

export const startsWithRegion = (url, path) =>
  path !== '' && url !== null && path !== null && url.startsWith(`/${path.toLowerCase()}/`)

const getRegionFromUrl = url => {
  const parts = url.split('/')
  const subdir = parts.length >= 2 ? parts[1].toUpperCase() : ""
  return getRegionByDirName(subdir)
}

export const isRegionalUrl = url => SUPPORTED_REGIONS.some(r => startsWithRegion(url, r.path))

const getRegion = code => {
  const region = SUPPORTED_REGIONS.find(
    r => typeof code !== 'undefined' && code !== null && r.code === code.toUpperCase()
  )
  return region || SUPPORTED_REGIONS[0]
}

const getRegionByDirName = subdir => {
  const region = SUPPORTED_REGIONS.find(
    r => typeof subdir !== 'undefined' && subdir !== null && r.path === subdir.toLowerCase()
  )
  return region || SUPPORTED_REGIONS[0]
}

export const getRegionPrefix = code => getRegion(code).path

const redirectionIsPossible = (regionCode, regionInitStatus) =>
  regionInitStatus === 'DONE' && regionCode != null && regionCode !== DEFAULT_REGION


export const getUrlRegionCode = url => {
  const relativePath = new URL(url).pathname
  
  const region = SUPPORTED_REGIONS.find(r => relativePath && r.path && relativePath.startsWith(`/${r.path}/`))
  return region ? region.code : DEFAULT_REGION
}

export const getAlternateRegionCode = path => {
  const region = SUPPORTED_REGIONS.find(
    r => typeof path !== 'undefined' && path !== null && r.path === path.toLowerCase()
  )
  return region ? region.code : DEFAULT_REGION
}

export const redirectToRegionPath = (path = '') => {
  let regionCode = useSelector(state => state.app.region)
  const regionInitStatus = useSelector(state => state.app.regionInitStatus)
  const dispatch = useDispatch()

  // repair wrong region code
  if (!isSupportedRegion(regionCode)) {
    console.log(`Incorrect region code detected: ${regionCode}`)
    regionCode = getAlternateRegionCode(regionCode)
    console.log(`Alternate region code: ${regionCode}`)
    dispatch(setRegion(regionCode))
  }

  if (redirectionIsPossible(regionCode, regionInitStatus)) {
    console.log(`Redirecting to regional home for ${regionCode}...`)
    const region = getRegion(regionCode)
    navigate(`/${region.path}/${path}`)
    dispatch(redirectedToRegion())
  }
}

export const detectPageRegion = (path = '') => {
  const regionInitStatus = useSelector(state => state.app.regionInitStatus)
  if (regionInitStatus !== 'DONE') {
    return
  }

  const dispatch = useDispatch()

  if (isRegionalUrl(path)) {
    const pageRegionCode = getRegionFromUrl(path).code
    const regionCode = useSelector(state => state.app.region)
    if (regionCode !== pageRegionCode) {
      console.log(`Detected page region ${pageRegionCode}`)
      dispatch(setPageRegion(pageRegionCode))
    } else {
      console.log(`No change. Region matches detected page region: ${pageRegionCode}`)
    }
  }
}
